import React from 'react'

export function RotateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 172 172"
      style={{ fill: '#000000' }}
    >
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="2"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="#ffffff">
          <path d="M23.97922,6.78594c-3.79707,0.05565 -6.83116,3.17694 -6.77922,6.97406v37.84h37.84c2.48118,0.03509 4.78904,-1.2685 6.03987,-3.41161c1.25083,-2.1431 1.25083,-4.79369 0,-6.93679c-1.25083,-2.1431 -3.55869,-3.4467 -6.03987,-3.41161h-13.19563c11.62259,-10.67002 27.08817,-17.2 44.15563,-17.2c36.17891,0 65.36,29.18109 65.36,65.36c0,36.17891 -29.18109,65.36 -65.36,65.36c-36.17891,0 -65.36,-29.18109 -65.36,-65.36c0.03509,-2.48118 -1.2685,-4.78904 -3.41161,-6.03987c-2.1431,-1.25083 -4.79369,-1.25083 -6.93679,0c-2.1431,1.25083 -3.4467,3.55869 -3.41161,6.03987c0,43.61533 35.50467,79.12 79.12,79.12c43.61533,0 79.12,-35.50467 79.12,-79.12c0,-43.61533 -35.50467,-79.12 -79.12,-79.12c-21.35739,0 -40.79135,8.49712 -55.04,22.29281v-15.41281c0.02542,-1.85848 -0.7021,-3.6482 -2.01701,-4.96184c-1.3149,-1.31364 -3.10532,-2.03944 -4.96378,-2.01223z"></path>
        </g>
      </g>
    </svg>
  )
}

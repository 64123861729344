import React from 'react'

export function DeleteIcon({ className }: { className: string | undefined }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="50"
      height="50"
      viewBox="0 0 172 172"
      style={{ fill: '#000000' }}
      className={className}
    >
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="#ffffff">
          <path d="M43.14781,34.4l-44.23625,51.6l44.23625,51.6h115.09219c7.56531,0 13.76,-6.19469 13.76,-13.76v-75.68c0,-7.56531 -6.19469,-13.76 -13.76,-13.76zM46.29219,41.28h111.94781c3.84313,0 6.88,3.03688 6.88,6.88v75.68c0,3.84313 -3.03687,6.88 -6.88,6.88h-111.93437l-38.33719,-44.72zM78.11219,62.92781l-4.86437,4.86437l18.20781,18.20781l-18.20781,18.20781l4.86437,4.86437l18.20781,-18.20781l18.20781,18.20781l4.86437,-4.86437l-18.20781,-18.20781l18.20781,-18.20781l-4.86437,-4.86437l-18.20781,18.20781z"></path>
        </g>
      </g>
    </svg>
  )
}

import React from 'react'

export function EnterIcon({ className }: { className: string | undefined }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="64"
      height="64"
      viewBox="0 0 172 172"
      style={{ fill: '#000000' }}
      className={className}
    >
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="#ffffff">
          <path d="M80.625,10.75c-8.84221,0 -16.125,7.28279 -16.125,16.125v26.875h-37.625c-8.84221,0 -16.125,7.28279 -16.125,16.125v64.5c0,8.84221 7.28279,16.125 16.125,16.125h118.25c8.84221,0 16.125,-7.28279 16.125,-16.125v-21.5v-43v-43c0,-8.84221 -7.28279,-16.125 -16.125,-16.125zM80.625,21.5h64.5c3.02579,0 5.375,2.34921 5.375,5.375v43v43v21.5c0,3.02579 -2.34921,5.375 -5.375,5.375h-118.25c-3.02579,0 -5.375,-2.34921 -5.375,-5.375v-64.5c0,-3.02579 2.34921,-5.375 5.375,-5.375h48.375v-37.625c0,-3.02579 2.34921,-5.375 5.375,-5.375zM107.5,43v5.375v48.375h-32.25v-21.5l-21.5,21.5l-5.375,5.375l5.375,5.375l21.5,21.5v-21.5h43v-59.125v-5.375z"></path>
        </g>
      </g>
    </svg>
  )
}

import fetch from 'isomorphic-fetch'
import moment from 'moment'

const BASE_URL = 'http://nytbee.com'
const API_PASSTHROUGH = 'https://ap-qlo.vercel.app/api/p'

const parser = new DOMParser()

export interface IGame {
  letters: string[];
  words: string[];
  center: string;
  geniusPoints: number;
}

export function gameKey(date: Date): string {
  return moment(date).format('YYYYMMDD')
}

export async function fetchGame(date: Date): Promise<IGame> {
  const url = `${BASE_URL}/Bee_${gameKey(date)}.html`
  const res = await fetch(API_PASSTHROUGH, {
    method: 'POST',
    body: JSON.stringify({ url }),
  }).then((res) => res.text())
  const doc = parser.parseFromString(res, 'text/html')

  const wordNodes = doc
    .querySelector('.answer-list .column-list')!
    .querySelectorAll('li')
  const words = Array.from(wordNodes)
    .filter((n) => !!n.textContent)
    .map((n) => n.textContent!.trim())
  const letters = Array.from(
    words.reduce((ret, word) => {
      word?.split('').forEach((l) => {
        ret.add(l)
      })
      return ret
    }, new Set<string>())
  )
  const puzzleNotes = Array.from(doc.querySelectorAll('h3')).filter(
    (n) => n.textContent && n.textContent.startsWith('Points Needed for Genius')
  )
  const geniusPoints = parseInt(
    (puzzleNotes[puzzleNotes.length - 1].textContent?.split(': ') || '').slice(
      1
    )[0],
    10
  )

  return {
    letters,
    words,
    center: findCenter(words, letters),
    geniusPoints,
  }
}

function findCenter(words: string[], letters: string[]) {
  const letterMap = letters.reduce((ret, l) => {
    ret[l] = true
    return ret
  }, {} as { [key: string]: boolean })
  // For each word
  words.forEach((w) => {
    letters.forEach((l) => {
      if (w.indexOf(l) < 0) {
        letterMap[l] = false
      }
    })
  })

  return letters.filter((l) => letterMap[l])[0]
}
